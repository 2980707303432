<template>
  <div class="yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumbItem>考试管理</el-breadcrumbItem>
        <el-breadcrumbItem
          :to="{
            path: '/manage/exam/check',
            query: { examId: $route.query.examId }
          }"
          >阅卷</el-breadcrumbItem
        >
        <el-breadcrumbItem>批阅</el-breadcrumbItem>
      </el-breadcrumb>
    </div>
    <div class="read-over-info">
      <div class="read-content" style="width: 20% ;">
        <div class="read-over-header">
          <div class="flexStyle">
            <p>参考人员</p>
            <div>
              <span class=" analysis">未批阅人数：</span>
              <span class=" num">{{ qTotal }}</span>
            </div>
          </div>
        </div>
        <div class="read-question-info">
          <div class="yt-flex-readOver">
            <div class="personal">
              <div v-for="(item, index) in employeeList" :key="index">
                <div
                  :class="IsF === item.questionVO.doRecord.id ? 'IsFocusDiv' : ''"
                  class="indexDiv"
                  @click="selecEmployeeData(item.questionVO.doRecord.id, item, index)"
                >
                  <div :style="{ color: em === index ? '#000000' : '' }">{{ index + 1 }}</div>
                  <div class="abDiv" v-show="item.status === 1">批</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="read-content" style="width: 80%;margin-left: 0">
        <div class="read-over-header">
          <p>考题信息（考生名称：{{ username }}）</p>
        </div>
        <div class="read-question-info">
          <div class=" message stem" v-html="questionDetail.stem"></div>
          <div class="yt-flex-layout">
            <div class=" message answer-info" style="white-space: nowrap">
              答案：
            </div>
            <div class=" message answer-info" v-html="questionDetail.questionAnswer || '暂无答案'"></div>
          </div>
          <div class="yt-flex-layout">
            <div class=" message analysis" style="white-space: nowrap">
              解析：
            </div>
            <div class=" message stem" v-html="questionDetail.analysis || '暂无解析'"></div>
          </div>
          <div class="yt-flex-layout space">
            <div class=" message analysis" style="white-space: nowrap">
              分值：
            </div>
            <div class=" message stem">
              {{ point }}
            </div>
          </div>
          <div class="line"></div>
          <div class="yt-flex-layout answer-item">
            <div class=" message analysis" style="white-space: nowrap">
              考生答案：
            </div>
            <div class=" message stem" v-html="questionDetail.doRecord.answer || '暂未答题'"></div>
          </div>
          <div class="yt-flex-readOver">
            <div class=" message analysis" style="white-space: nowrap">
              考生得分：
            </div>
            <el-input-number v-model="questionDetail.points" :min="0" :max="point" placeholder="请设置分数" style="width: 150px;" />
            <div class=" message analysis" style="margin-left: 10px">
              (默认满分)
            </div>
          </div>
        </div>
        <div class="bottom-button">
          <el-button @click="backRoute">返回</el-button>
          <el-button :loading="Iswaiting" type="primary" @click="nextQById(questionDetail)">打分</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import review from '@api/review'
export default {
  name: 'revieTopic',
  data() {
    return {
      em: 0,
      point: 10,
      qTotal: 0,
      IsF: null,
      employeeList: [],
      readOverList: [],
      username: '',
      questionDetail: {
        points: null,
        analysis: null,
        doRecord: {
          answer: null
        }
      },
      Iswaiting: false,
      IsHoverRef: false,
      IsReverse: false,
      examId: 0,
      questionId: 0
    }
  },
  created() {
    let res = this.$handleParams('examCheckData')
    this.examId = res.examId
    this.questionId = res.questionId
    this.getAnsweremployee(this.examId, false, this.questionId)
    this.getQTotal(this.examId, this.questionId, false)
  },
  methods: {
    getAnsweremployee(examId, reverse, qId) {
      review.getAllAnswerEmployee(examId, reverse, qId).then(res => {
        this.employeeList = res.res
        this.employeeList.map(item => {
          item.status = 0
        })
        if (this.employeeList.length > 0) {
          this.username = this.employeeList[0].userName
          this.questionDetail = this.employeeList[0].questionVO
          this.point = this.employeeList[0].questionVO.points
          this.em = 0
          this.IsF = this.employeeList[0].questionVO.doRecord.id
        } else {
          this.message('试卷待批阅题目已完成,即将返回阅卷页面', 'warning')
          this.$router.back()
        }
      })
    },
    selecEmployeeData(id, data, em) {
      this.em = em
      this.questionDetail = data.questionVO
      this.username = data.userName
      this.point = data.points
      this.IsF = id
    },
    getQTotal(id, questionId, reverse) {
      review.reviewExamQTotal(id, questionId, reverse).then(res => {
        this.qTotal = res.res
      })
    },
    backRoute() {
      this.$router.push({
        path: '/manage/exam/check',
        query: { examId: this.examId.toString() }
      })
    },
    nextQById(question) {
      this.Iswaiting = true
      let payload = {
        answerPaperId: question.doRecord.answerPaperId,
        questionId: question.doRecord.questionId,
        recordId: question.doRecord.id,
        score: question.points
      }
      review
        .recordPointByQId(payload)
        .then(res => {
          if (res.res) {
            this.message('成功', 'success')
            this.$set(this.employeeList[this.em], 'status', 1)
            if (this.em + 1 < this.employeeList.length) {
              this.em = this.em + 1
              this.username = this.employeeList[this.em].userName
              this.questionDetail = this.employeeList[this.em].questionVO
              this.IsF = this.employeeList[this.em].questionVO.doRecord.id
            } else {
              this.em = this.employeeList.findIndex(item => {
                return item.status === 0
              })
            }
            // this.getAnsweremployee(this.examId, false, this.questionId)
          } else {
            this.message('打分失败', 'warning')
          }
        })
        .catch(() => {
          // this.message('打分失败123', 'warning')
        })
        .finally(() => {
          this.getQTotal(this.examId, this.questionId, false)
          this.Iswaiting = false
        })
    },
    message(msg, type) {
      this.$message({ message: msg, type: type, duration: 2000 })
    }
  }
}
</script>

<style lang="less" scoped>
.read-over-info {
  display: flex;
  height: 100%;
  background-color: #f8f8f8;
  .read-content {
    margin: 0 10px 10px 10px;
    height: calc(100% - 10px);
    background-color: #ffffff;
    .read-over-header {
      height: 46px;
      padding: 12px;
      background: #e4e9ef;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
      border-radius: 2px 2px 0 0;
      p {
        font-size: 16px;
        font-weight: bold;
        color: #000000;
      }
    }
    .read-question-info {
      padding: 20px;
      height: calc(100% - 100px);
      overflow-y: auto;
      overflow-x: hidden;
      .message {
        font-weight: 400;
      }
      .stem {
        color: #000000;
      }
      .answer-info {
        margin: 40px 0 20px 0;
        color: #06c281;
      }
      .analysis {
        color: #999999;
      }
      .line {
        margin-left: -20px;
        width: calc(100% + 40px);
        height: 1px;
        background: #e2e4e8;
      }
      .num {
        color: #ff5050;
      }
      .answer-item {
        margin: 40px 0 20px 0;
      }
      .personal {
        display: flex;
        flex-wrap: wrap;
        div {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .indexDiv {
          position: relative;
          width: 43px;
          height: 43px;
          border-radius: 4px;
          text-align: center;
          line-height: 43px;
          div {
            width: 38px;
            height: 38px;
            color: #ffffff;
            background: #7caeef;
            cursor: pointer;
            border-radius: 4px;
          }

          .abDiv {
            position: absolute;
            color: #fff;
            font-size: 12px;
            left: 3px;
            top: 3px;
            background: #7ca2e2;
            width: 16px;
            height: 15px;
          }
        }
      }
      .read-over {
        color: #ffffff;
        background: #7caeef;
      }
      .un-read-over {
        color: #000000;
        background: rgba(255, 255, 255, 0.39);
      }
    }
  }
  .bottom-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .space {
    margin: 20px 0 40px 0;
  }
  .yt-flex-readOver {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .border {
    border: #2a9bff 1px solid;
  }
}
.analysis {
  color: #999999;
}
.num {
  color: #ff5050;
}
.flexStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
