import config from '../config/config'
import { axios } from './index'

const baseUrl = config.baseUrl + '/exam/api/v1'
export default {
  // 获取所有待批阅的试卷
  reviewExamList() {
    return axios.get(`${baseUrl}/exam/mark/exams`)
  },
  // 获取开始待批阅的题目
  reviewExamQuestion(examId) {
    return axios.get(`${baseUrl}/exam/mark/exam/questions?examId=${examId}`)
  },
  //查找考试待批阅的题目数量
  reviewExamQTotal(examId, questionId, reverse) {
    return axios.get(`${baseUrl}/exam/mark/exam/question/count?examId=${examId}&questionId=${questionId}&reverse=${reverse}`)
  },
  //获取考试一道待批阅的题目
  reviewExamQByOne(examId, questionId, reverse) {
    return axios.get(`${baseUrl}/exam/mark/exam/question/answer?examId=${examId}&questionId=${questionId}&reverse=${reverse}`)
  },
  // 打分
  recordPointByQId(payload) {
    return axios.post(`${baseUrl}/exam/mark/answer/score`, payload)
  },
  // 获取答题的参考人
  getAllAnswerEmployee(eId, reverse, qId) {
    return axios.get(`${baseUrl}/exam/mark/exam/question/all/answer?examId=${eId}&reverse=${reverse}&questionId=${qId}`)
  }
}
